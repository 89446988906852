.use-flat-fee-modal-container {
  position: absolute;
  width: 375px;
  min-height: 363px;
  z-index: 50;
  left: 40%;
  top: 16%;
  background: #ffffff;
  border-radius: 20px;
}

.use-flat-fee-modal-container {
  padding: 25px 25px;
}

.use-flat-fee-modal-header {
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 15px;
  width: 100%;
  font-weight: 700;
  font-size: 22px;
  line-height: 20px;
  color: #3a0ca3;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.use-flat-fee-modal-sub-header {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
  margin-top: 5px;
}

.flat-fee-form-wrapper form {
  margin-top: 20px;
}

.flat-fee-form-wrapper form .form-group {
  width: 100%;
  margin-bottom: 15px;
}

.flat-fee-form-wrapper form .form-group input {
  width: 100%;
}

.submit-flat-fee-btn-wrapper {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.submit-flat-fee-btn-wrapper .spinner-container {
  margin-right: 10px;
}

.submit-flat-fee-btn-wrapper button {
  width: 100%;
  height: 50px;
  border-radius: 10px;
  border: none;
  outline: none;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
}

.submit-flat-fee-btn-wrapper button {
  background-color: #3a0ca3;
  color: #ffffff;
}
