.account-awaiting-approval-section {
  padding-top: 250px !important;
  min-height: 100vh;
}

.awaiting-approval-header-title {
  justify-content: center;
}

.awaiting-approval-sub-title {
  font-size: 16px;
}

.awaiting-approval-sub-text-body {
  max-width: 300px;
  margin-top: 25px;
}

.awaiting--go-back-button-wrapper {
  margin-top: 40px;
}

.awaiting--go-back-button-wrapper button {
  width: 150px;
  height: 45px;
}
