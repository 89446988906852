/* Secondary Table Filter Option Container*/
.secondary--table-filter-options-container {
  position: relative;
  display: flex;
  align-items: center;
  column-gap: 48px;
}

.secondary--table-filter-options-container::after {
  content: "";
  position: absolute;
  width: 95%;
  height: 2px;
  left: 0;
  bottom: 0;
  border-radius: 4px;
  background-color: #d2c1fb;
}

.secondary--table-filter-option {
  position: relative;
  padding: 8px 5px;
  cursor: pointer;
  font-family: "moderat-medium";
  line-height: 20px;
  color: #444444;
}

/* Secondary Active Filter Option */
.secondary--active-filter-option {
  color: #3a0ca3 !important;
}

.secondary--active-filter-option::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  left: 0;
  bottom: 0;
  border-radius: 4px;
  background-color: #3a0ca3;
  z-index: 4;
}
.credit-type-wrapper {
  color: #008505;
}

.debit-type-wrapper {
  color: #b3261e;
}
