.merchant-stock-container {
  padding-bottom: 30px;
  position: relative;
  overflow: hidden;
}

.merchant-stock-container--inner {
  max-width: 1260px;
  margin: auto;
  margin-top: 40px;
}

/* stock-container--row-one */
section.stock-container--row-one {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* .add-new-stock-button {
  display: flex;
  align-items: center;
}

.add-new-stock-button button {
  background-color: #3a0ca3;
  width: 200px;
  height: 50px;
  border-radius: 10px;
  border: none;
  outline: none;
  font-family: "moderat-medium";
  font-size: 14px;
  line-height: 15px;
  color: #ffffff;
  cursor: pointer;
} */

/* ALL STOCKS TABLE */

/* All Orders Table Container */
.all-stocks-table-container {
  margin-top: 40px;
}

.all-stocks-table {
  width: 100%;
  background-color: #f0ecf8;
  margin-top: 20px;
  border-radius: 10px;
  overflow: hidden;
  /* Remove table gaps and margins */
  border-spacing: 0px;
}

.all-stocks-table thead {
  background-color: #f0ecf8;
}
/* Table Head , Table Row */
.all-stocks-table thead tr {
  border-radius: 5px 5px 0px 0px;
}

/* Table Head , Table Row, Thable header*/
.all-stocks-table thead tr th {
  padding: 20px 10px;
  font-family: "moderat-semibold";
  font-size: 14px;
  line-height: 12px;
  color: #3a0ca3;
}

.all-stocks-table thead tr th:nth-child(1) {
  padding-left: 95px;
}

/* Table Body */
.all-stocks-table tbody {
  margin: 0;
}

.all-stocks-table tbody tr {
  overflow: hidden;
}

.all-stocks-table tbody tr:hover td {
  background-color: #f8f8fa;
}
.all-stocks-table tbody tr > tr:hover td {
  background-color: rebeccapurple;
}

/* Table Body , Table Data*/
.all-stocks-table tbody tr td {
  background-color: #ffffff;
  padding: 16px 10px;
  font-family: "moderat-medium";
  color: #2e2e2e;
  font-size: 15px;
  border-bottom: 1px solid #f3f3f3 !important;
}

.all-stocks-table tbody tr td:nth-child(1) {
  padding-left: 40px;
}

.td-select-row-box--wrapper {
  display: flex;
  justify-content: center;
}

.td-select-row-box--wrapper button {
  width: 20px;
  height: 20px;
  border: 1px solid #c0bace;
  border-radius: 4px;
  background-color: inherit;
}

/* Show Variants */
.td-show-variants--wrapper button {
  border: none;
  outline: none;
  background-color: inherit;
}

.td-show-variants--wrapper button:focus {
  border: none;
  outline: none;
}

.td-show-variants--wrapper button img {
  filter: invert(1);
}

/* Edit Stock Row Column  */
.td-edit-stock-row--wrapper a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 31px;
  height: 31px;
  background: #ede9f7;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}

/* Stock Status Column */
.td-stock-status--wrapper {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #a9a9bc;
  transform: translateY(4px);
}

.stock-status-color-box {
  width: 29px;
  height: 13px;
  margin-right: 7px;
}

.out-of-stock-color-box {
  background-color: #cd4c4c;
}

.not-approved-color-box {
  background-color: #ecbe1c;
}

.new-stock-color-box {
  background-color: #00ba07;
}

/* Normal Status wrapper column */
.td-status--wrapper {
  display: flex;
  align-items: center;
}

/* Toggle Switch Column */

.toggle-switch-container {
  margin-left: 8px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 28px;
  height: 14px;
  transform: translateY(4px);
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ede9f7;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 0;
  bottom: 0;
  background-color: #cd4c4c;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider:before {
  background-color: #00ba07;
  -webkit-transform: translateX(14px);
  -ms-transform: translateX(14px);
  transform: translateX(14px);
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

.td-show-variants--wrapper button img {
  transition: all ease-in-out 0.2s;
}

.td-show-variants--wrapper button img.opened {
  transform: rotate(180deg);
}

.active-table-row td {
  background-color: #f8f8fa !important;
}

.stock-loader-wrapper {
  margin-top: 20px;
}

/*overwrite bootstrap table padding*/
/* .hiddenRow {
  padding: 0 !important;
} */

.collapse {
  display: none;
}

tr.collapse.show {
  display: table-row;
  transition: all ease-in-out 0.3s;
}

.collapse.show {
  display: block;
  width: 100%;
}

.tr--variants-row td {
  background-color: #f8f8fa !important;
  padding-top: 20px;
}

.td-variants-name--wrapper {
  display: block !important;
}

.variants--profile-image-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

/* Variants Total Quantity */
/* Variants Catgory Column */
/* Variants Stock Price */
/* Variants Status */
.td--variants-total-quantity-wrapper,
.td--variants-stock-category-wrapper,
.td--variants-stock-price-wrapper,
.td--variants-status-wrapper {
  margin-bottom: 58px;
  margin-top: 12px;
}

/* Variants Edit Stock Row */
.td--variants-edit-stock-wrapper {
  margin-bottom: 48px;
  margin-top: 12px;
}

/* Variant Stock Status Row */
.td--variants-stock-status-wrapper {
  margin-bottom: 60px;
  margin-top: 12px;
}

/*  */
.merchant-stock-message-wrapper .error-handler-container,
.merchant-stock-message-wrapper .success-handler-container {
  top: 135px !important;
}

.delete-stock-btn-wrapper {
  /* position: absolute;
  bottom: 0;
  left: 0; */
  margin-top: 50px;
}

.delete-stock-btn-wrapper button {
  width: 200px;
  height: 50px;
  border-radius: 10px;
  outline: none;
  border: none;
  cursor: pointer;
  font-size: 15px;
  line-height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ce2e2e;
  color: #ffffff;
}

/* Media queeries */
@media (max-width: 1320px) {
  .merchant-stock-container--inner {
    padding: 0 25px;
  }
}
