.account-verification-container {
  position: relative;
  overflow: hidden;
}

.account-verification-wrapper {
  background-color: #ffffff;
  max-width: 1200px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 240px;
  padding-bottom: 20px;
}

.verification-message-wrapper .error-handler-container,
.verification-message-wrapper .success-handler-container {
  top: 100px;
}

/* Section Header */
.dashboard-awaiting-data-section-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 25px;
}
