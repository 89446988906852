body {
  margin: 0;
}

* {
  box-sizing: border-box;
}

button:focus {
  outline: none;
}

@font-face {
  /* 300 */
  font-family: moderat-light;
  src: url(./styles/fonts/Moderat-Thin.ttf);
}

/* 400 */
@font-face {
  font-family: moderat-regular;
  src: url(./styles/fonts/Moderat-Regular.ttf);
}

@font-face {
  /* 500 */
  font-family: moderat-medium;
  src: url(./styles/fonts/Moderat-Medium.ttf);
}

@font-face {
  /* 600 */
  font-family: moderat-semibold;
  src: url(./styles/fonts/Moderat-Bold.ttf);
}

@font-face {
  /* 700 */
  font-family: moderat-bold;
  src: url(./styles/fonts/Moderat-Black.ttf);
}

/* Moderat-- font override */
body {
  font-family: "moderat-regular";
}

.app {
  position: relative;
  font-family: "moderat-regular";
  background-color: #f8f8fa;
}

button {
  font-family: "moderat-medium";
  cursor: pointer;
  outline: none;
}

button,
textarea {
  font-family: "moderat-regular";
}

textarea {
  resize: none;
}

input::placeholder {
  font-size: 15px;
  line-height: 25px;
  font-family: "moderat-regular";
  font-weight: 400;
  color: #222222;
}

input:focus,
textarea:focus,
select:focus {
  outline: 1px solid #3a0ca3;
}

input[type="password"] {
  font-weight: 600;
}

button:focus {
  outline: none;
}
/* Remove the default angle icon in select dropdown */

select {
  /* For Firefox */
  -webkit-appearance: none;
  /* for Chrome */
  -moz-appearance: none;
  appearance: none;
  text-indent: 1px;
  text-overflow: "";
}

/* For IE10 */
select::-ms-expand {
  display: none;
}

.upload-loading-spinner-wrapper {
  width: 15px;
  height: 15px;
  border: 4px solid #cccccc; /* Light grey */
  border-top: 4px solid #3a0ca3; /* Blue */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
  z-index: 40;
  margin: 0 4px;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.app--actions-handler-wrapper .success-handler-container,
.app--actions-handler-wrapper .error-handler-container {
  top: 80px !important;
}

.below-table-loader-wrapper {
  margin-top: 20px;
}

/*  */
.form-group {
  position: relative;
}
/*  */
/*  */
.none {
  display: none;
}
