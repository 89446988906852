.dashboard-setup-container {
  margin-top: 100px;
  width: 100%;
  z-index: 40;
  background-color: white;
  font-family: moderat-regular;
}

.dashboard-setup-container--inner {
  max-width: 1200px;
  margin: auto;
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

/* Dashboard Setup Onboarding Section */
section.dashboard-setup-onboarding-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  padding-bottom: 20px;
}

/* Section Header */
.dashboard-setup-onboarding-section-header {
  width: 300px;
}

.dashboard-setup-container-onboarding-section-title {
  font-family: "moderat-bold";
  font-size: 40px;
  line-height: 54px;
  background: linear-gradient(281.7deg, #b5179e 3.1%, #3a0ca3 96.25%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

/* Emoji Image */
.dashboard-setup-container-onboarding-section-title img {
  transform: translateY(5px);
}

/* Container Main Section */
.dashboard-setup-onboarding-section-main-container {
  margin-top: 40px;
}

/* Go to setup links wrapper container */
.go-to-setup-links-wrapper-container {
  position: relative;
}

.go-to-setup-link-wrapper {
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;
}

.go-to-setup-link-wrapper button {
  width: 600px;
  height: 90px;
  border-radius: 20px;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 80px;
  padding-right: 30px;
  cursor: pointer;

  /* Text Styles */
  font-family: "moderat-medium";
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #2e2e2e;
}

/* Star image Wrapper */
.star-image-wrapper {
  /* background-color: red; */
  position: absolute;
  bottom: -8px;
  left: -2px;
}

.go-to-shop-details-setup-wrapper button {
  background-color: #fbf9ff;
}

.go-to-bank-account-setup-wrapper button {
  background-color: #f8eaf6;
}

.go-to-stock-setup-wrapper button {
  background-color: #ffe9e9;
}

/*  SETUP LAYOUT SECTION */
/* Dashboard Setup layout Section */
.dashboard-setup-layout-container {
  position: relative;
  max-width: 1000px;
  /* background-color: rebeccapurple; */
  margin: auto;
  padding: 50px 0;
  padding-left: 50px;
  overflow: hidden;
}

.dashboard-setup-top-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-left: 25px; */
}

.dashboard-setup-top-container--left-wrapper {
  display: flex;
  align-items: center;
}

.dashboard-setup-top-container .back-button-wrapper button {
  width: 50px;
  height: 50px;
  background-color: #f8f8fa;
  border-radius: 10px;
  border: none;
  outline: none;
  cursor: pointer;
}

.dashboard-setup-top-title {
  font-family: "moderat-semibold";
  font-size: 32px;
  line-height: 50px;
  text-align: center;
  color: #1a0404;
  margin-left: 25px;
}

/* Setup Layout main Container */
.dashboard-setup-layout-main-container {
  display: flex;
  margin-top: 40px;
}

.dashboard-setup-layout-main-container.colum-grid {
  flex-direction: column;
}

/* Dashboard Setup LEFT Section */
.dashboard-setup-layout--left-section {
  width: 400px;
  margin-top: 60px;
  position: relative;
}

.log-out-btn-wrapper {
}

.log-out-btn-wrapper button {
  width: 200px;
  height: 50px;
  border-radius: 10px;
  outline: none;
  border: none;
  cursor: pointer;
  font-size: 15px;
  line-height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ce2e2e;
  color: #ffffff;
}

.form-navigation-link button {
  width: 374px;
  height: 68px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  cursor: pointer;
  border: none;
  outline: none;
  /* Text styles */
  /* font-family: "moderat-medium"; */
  font-size: 17px;
  line-height: 18px;
  color: #a9a9bc;
  background-color: #ffffff;
}

.form-navigation-link button img {
  margin-right: 15px;
}

/* Dashboard Setup RIGHT Section */
.dashboard-setup-layout--right-section {
  margin-left: 45px;
}

.colum-grid .dashboard-setup-layout--right-section {
  margin-left: 0;
}

/* Profile photo update form group/ */
.account-details-setup-profile-photo-form-group {
  display: flex;
  align-items: center;
}

.profile-photo-display-wrapper {
  position: relative;
  width: 150px;
  height: 150px;
  overflow: hidden;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.2);
}

.profile-photo-display-wrapper img {
  top: -25%;
  left: -25%;
  position: absolute;
  object-fit: contain;
  width: 150%;
  height: 150%;
}

.upload-profile-photo-input-wrapper {
  width: 159px;
  height: 30px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  background: #ede9f7;
  border-radius: 5px;
  font-family: "moderat-medium";
  font-size: 12px;
  line-height: 12px;
  color: #0d0520;
}

.upload-profile-photo-input-wrapper input {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  opacity: 0;
}

.active-form-nav-link {
  background-color: #f8f8fa !important;
  color: #2e2e2e !important;
}

/* All formgroups */
.account-details-setup-profile-photo-form-group,
.account-details-setup--inner form .form-group,
.shop-details-setup--inner form .form-group,
.shop-details-setup-cac-doc-form-group,
.bank-account-setup--inner .form-group {
  margin-bottom: 25px;
}

/* All inputs */
.account-details-setup--inner form .form-group input,
.shop-details-setup--inner form .form-group > input,
.shop-details-setup--inner form .form-group select,
.shop-details-setup--inner form .form-group > textarea,
.shop-details-setup-cac-doc-form-group .file-form-group--inner,
.bank-account-setup--inner .form-group input,
.bank-account-setup--inner .form-group select {
  width: 500px;
}
/* .delivery-options-setup--inner .form-group input {
  width: 500px;
} */

.dashboard-setup-layout-container .form-group input:disabled {
  background-color: inherit;
}

.submit-setup-form-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 45px;
}

.submit-setup-form-button-wrapper button {
  width: 250px;
  height: 50px;
  background-color: #3a0ca3;
  border-radius: 10px;
  border: none;
  outline: none;
  cursor: pointer;
  /* Text styles */
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  color: #ffffff;
  margin-left: 10px;
}

.use-a-flat-fee-option-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 15px;
}

.use-a-flat-fee-option-button-wrapper button {
  width: 230px;
  height: 50px;
  background-color: #3a0ca3;
  border-radius: 10px;
  border: none;
  outline: none;
  cursor: pointer;
  /* Text styles */
  font-weight: 500;
  font-size: 16px;
  line-height: 15px;
  color: #ffffff;
  margin-left: 10px;
}

/* Shop details form group */
.shop-details-setup-shop-address-form-group textarea {
  height: 100px;
  padding: 5px 15px;
  border: 1px solid #c0bace;
  box-sizing: border-box;
  border-radius: 10px;
  /* text styles  */
  font-size: 15px;
  line-height: 25px;
  /* font-family: "moderat-regular"; */
  font-weight: 400;
  color: #1a0404;
}

/* CAC File Input Form groups / Fil Input form group */
.shop-details-setup-cac-doc-form-group .file-form-group--inner {
  height: 100px;
  border: 1px dashed #c0bace;
  border-radius: 10px;
}

.file-form-group--inner {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* File Input Wrapper */
.shop-details-setup-cac-doc-form-group .file-input-wrapper {
  min-width: 160px;
  max-width: 400px;
  min-height: 33px;
  position: relative;
  background-color: #ede9f7;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* word-wrap: break-word; */
}

/* File Input */
.file-form-group--inner .file-input-wrapper input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  outline: none;
}

/* File Input Title */
.file-form-group--inner .file-input-wrapper .file-input-title {
  height: 100%;
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  color: #2e2e2e;
  padding: 0 3px;
}

.file-form-group label {
  font-weight: 400;
  display: block;
  font-size: 14px;
  line-height: 14px;
  color: #2e2e2e;
  margin-bottom: 5px;
}

.file-form-group label span {
  font-family: "moderat-light";
  font-style: italic;
  font-size: 12px;
  line-height: 12px;
  color: #2e2e2e;
}

.preview-cac-document-icon a {
  margin-left: 8px;
}

.preview-cac-document-icon a img {
  height: 16px;
  filter: invert(0.3);
}

/* Delivery details setup container */
.delivery-options-setup--inner .delivery-details-form-wrapper {
  /* margin-top: 30px; */
}

.delivery-options-setup--inner .form-group-wrapper {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  column-gap: 25px;
  row-gap: 30px;
  margin-top: 30px;
}

.delivery-options-setup--inner .form-group-wrapper .form-group {
  grid-column: span 3;
}

.delivery-options-setup--inner
  .delivery-details-form-wrapper
  .form-header-wrapper {
  width: 460px;
  height: 27px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  background-color: #f8f8fa;
  font-size: 14px;
  line-height: 14px;
  color: #3a0ca3;
  margin-top: 30px;
}

.delivery-options-setup--inner .delivery-details-form-wrapper:nth-child(1) {
  margin-top: 0;
}

/* Form Boy Wrapper */
.delivery-options-setup--inner .form-body-wrapper {
  /* margin-top: 30px; */
}

.delivery-item-price-form-group-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.delivery-item-price-form-group-wrapper .form-group input {
  width: 220px;
}

.delivery-details-form-row-two,
.delivery-details-form-row-three {
  margin-top: 50px;
}

.setup-layout-error-success-message-wrapper {
  top: 0px !important;
}

.push-container-down {
  margin-top: 20px;
}

/*  */
/*  */
/*  */
.none {
  display: none;
}
