.products-nav-section {
}

.products-filter-container {
  position: relative;
  border-bottom: 1px solid #a9a9bc;
  display: flex;
  align-items: center;
  column-gap: 10px;
  /* justify-content: center; */
}

.products-filter-option {
  position: relative;
  min-width: 200px;
  width: max-content;
  padding: 12px 0;
  cursor: pointer;
  text-align: center;
  font-weight: 500;
  /* font-size: 16px; */
  line-height: 20px;
  color: #a9a9bc;
}

/* Primary Active Filter Option */
.active-filter-option {
  color: #1a0404;
}

.active-filter-option::after {
  content: "";
  position: absolute;
  max-width: 220px;
  width: 100%;
  height: 4px;
  left: 0;
  bottom: 0;
  /* Gradient */
  background: linear-gradient(281.7deg, #b5179e 3.1%, #3a0ca3 96.25%);
}
