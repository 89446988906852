.app-page-container {
  position: relative;
  overflow: hidden;
}

.app-page-container--inner {
  position: relative;
  /* max-width: 1260px; */
  margin: auto;
  overflow: hidden;
}

/* wellness-container-main-section */
section.app-container-main-section {
  position: relative;
  min-height: 100vh;
  background: #f8f8fa;
  padding: 0 24px;
  margin-left: 260px;
  margin-top: 80px;
  padding-top: 60px;
}

section.app-container-without-sidebar {
  padding-top: 80px;
}

.app-page--top-container {
  display: flex;
  align-items: center;
  column-gap: 40px;
}

/* merchants--top--box-container */
.merchants--top--box-container {
  width: 260px;
  height: 160px;
  background: #ffffff;
  border-radius: 20px;
  padding: 20px 0 20px 30px;
  color: #0d0520;
}

.box-title-wrapper {
  font-family: "moderat-semibold";
  font-size: 13px;
  line-height: 12px;
  display: flex;
  align-items: center;
}

.box-value-wrapper {
  margin-top: 38px;
  font-family: "moderat-semibold";
  font-size: 32px;
  line-height: 38px;
  word-wrap: break-word;
}

/* Media Queries */
/* @media (min-width: 1400px) {
  .app-page-container--inner {
    max-width: 1500px;
  }
}

@media (min-width: 1500px) {
  .app-page-container--inner {
    max-width: 1700px;
  }
} */
