.stock-orders-page {
}

/* Section One / Order Summary    */
section.orders-containers--row-one {
  display: flex;
  justify-content: center;
}

.order-summary-row-section--inner {
  width: 900px;
  background: #f8f8fa;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

/* orders-summary-wrapper */
.orders-summary-wrapper {
  display: flex;
  align-items: center;
  padding: 5px 50px;
}

.box-icon-wrapper {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-right: 15px;
}

.order-summary-title {
  font-family: "moderat-medium";
  font-size: 15px;
  line-height: 14px;
  color: #a9a9bc;
}

.order-summary-value {
  font-family: "moderat-semibold";
  font-size: 24px;
  line-height: 24px;
  margin-top: 4px;
}

.completed-orders-summary-wrapper {
  border-left: 4px solid #f0f0f0;
  border-right: 4px solid #f0f0f0;
}

.pending-orders-summary-wrapper .box-icon-wrapper {
  background: rgba(236, 190, 28, 0.1);
}

.completed-orders-summary-wrapper .box-icon-wrapper {
  background: #dff2e2;
}

.total-orders-summary-wrapper .box-icon-wrapper {
  background: #ede9f7;
}

.pending-orders-summary-wrapper .order-summary-value {
  color: #ecbe1c;
}

.completed-orders-summary-wrapper .order-summary-value {
  color: #00ba07;
}

.total-orders-summary-wrapper .order-summary-value {
  color: #3a0ca3;
}

/* Section Two / Orders Table    */
section.orders-containers--row-two {
  margin-top: 50px;
}

/* orders-table-filter-option-wrapper */
.orders-table-filter-option-wrapper {
  display: flex;
  align-items: center;
}

.orders-table-filter-row-title {
  font-family: "moderat-medium";
  /* font-size: 15px; */
  line-height: 15px;
  color: #a9a9bc;
  margin-right: 10px;
}

.orders-table-filter-option-wrapper .form-group {
  margin-left: 20px;
}

.order-status-filter-form-group {
  /* position: relative !important; */
}

.orders-table-filter-option-wrapper .form-group > .select-dropdown-wrapper {
  background: #f8f8fa;
}

.orders-table-filter-option-wrapper .form-group select {
  width: 150px;
  border: none;
  color: #dadada;
}

.orders-table-filter-option-wrapper select option {
  font-size: 14px;
  color: #0d0520;
}

.orders-table-filter-option-wrapper .form-group .dropdown-icon img {
  top: 35%;
}

/* All Orders Table Container */
.all-orders-table-container {
  margin-top: 40px;
}

.all-orders-table {
  width: 100%;
  background-color: #f0ecf8;
  margin-top: 20px;
  border-radius: 10px;
  overflow: hidden;
  /* Remove table gaps and margins */
  border-spacing: 0px;
}

.all-orders-table thead {
  background-color: #f0ecf8;
}
/* Table Head , Table Row */
.all-orders-table thead tr {
  border-radius: 5px 5px 0px 0px;
}

/* Table Head , Table Row, Thable header*/
.all-orders-table thead tr th {
  padding: 20px 10px;
  font-family: "moderat-semibold";
  font-size: 14px;
  line-height: 12px;
  color: #3a0ca3;
}

.all-orders-table thead tr th:nth-child(1) {
  padding-left: 65px;
}

/* Table Body */
.all-orders-table tbody {
  margin: 0;
}

.all-orders-table tbody tr {
  overflow: hidden;
}

.all-orders-table tbody tr:hover td {
  background-color: #f8f8fa;
}

/* Table Body , Table Data*/
.all-orders-table tbody tr td {
  background-color: #ffffff;
  padding: 16px 10px;
  font-family: "moderat-medium";
  color: #2e2e2e;
  font-size: 15px;
  border-bottom: 1px solid #f3f3f3 !important;
}

.all-orders-table tbody tr td:nth-child(1) {
  padding-left: 10px;
}

/* Prouct Name Wrapper */
.td-product-name--wrapper {
  display: flex;
  align-items: center;
  max-width: 300px;
}

.td-product-name--wrapper .profile-image-wrapper {
  overflow: hidden;
  width: 40px;
  height: 40px;
  /* object-fit: cover; */
  border-radius: 4px;
  margin-right: 15px;
}

.td-product-name--wrapper .profile-image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.order-pending-wrapper {
  color: #ecbe1c !important;
}

.order-completed-wrapper {
  color: #00ba07 !important;
}

.order-cancelled-wrapper {
  color: #b5179e;
}

/* .order-shipped-wrapper {
  color: #3a0ca3 !important;
}

.order-rejected-wrapper {
  color: #ce2e2e !important;
} */

/*  */
/*  */
/*  */
.none {
  display: none;
}

/* Order preview container */
.order-preview-container {
  max-width: 1100px;
  /* background-color: rebeccapurple; */
  margin: auto;
  padding: 50px 0;
  padding-left: 50px;
}

.order-preview-top-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 55px;
}

.order-preview-top--left {
  display: flex;
  align-items: center;
}

.order-preview-top-container .back-button-wrapper button {
  width: 50px;
  height: 50px;
  background-color: #f8f8fa;
  border-radius: 10px;
  border: none;
  outline: none;
  cursor: pointer;
}

.order-preview-top-title {
  font-family: "moderat-semibold";
  font-size: 32px;
  line-height: 50px;
  text-align: center;
  color: #1a0404;
  margin-left: 25px;
}

/* Order preview container inner */
.order-preview-container--inner {
  display: flex;
  margin-top: 40px;
}

.complete-order-button-wrapper button {
  width: 150px;
  height: 50px;
  border-radius: 10px;
  border: none;
  outline: none;

  font-family: "moderat-medium";
  font-size: 14px;
  line-height: 15px;
  color: #ffffff;
  cursor: pointer;
}

.complete-order-button button {
  background-color: #3a0ca3;
}

.order-processing-button button {
  background-color: #b5179e;
}

.order-completed-button button {
  background-color: #00ba07;
  cursor: default !important;
}

/* Setup Layout main Container */
.order-preview-container--inner {
  display: flex;
  margin-top: 60px;
}

/* order-preview-left-section */
.order-preview-left-section {
}

.order-items-container {
  width: 400px;
  height: max-content;
  background: #f8f8fa;
  border-radius: 5px;
  padding: 20px 20px;
}

.order-items--title,
.order-items--title {
  font-family: "moderat-semibold";
  font-size: 14px;
  line-height: 12px;
  color: #3a0ca3;
  margin-bottom: 20px;
}

.order-items-box-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.order-items-box-row--left,
.order-items-box-row--right {
  display: flex;
  align-items: center;
}

.order-items-box-row--right {
  margin-left: 6px;
}

.order-items-box-row:nth-last-child(1) {
  margin-bottom: 0;
}

.order-items-box-row .order-item-img-wrapper {
  width: 60px;
  height: 60px;
  border-radius: 5px;
  overflow: hidden;
  margin-right: 20px;
}

.order-items-box-row .order-item-img-wrapper img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.order-item-name {
  font-family: "moderat-medium";
  font-size: 15px;
  /* line-height: 150%; */
  color: #2e2e2e;
}
/* order-preview-right-section */
.order-preview-right-section {
  margin-left: 50px;
}

/* Order details Container */
.order-details-container {
  width: 528px;
  height: max-content;
  padding: 20px 20px 20px 40px;

  background: #f8f8fa;
  border-radius: 5px;
}

.order-details--row-one,
.order-details--row-two {
  display: flex;
}

.order-details--row-one div:first-child,
.order-details--row-two div:first-child {
  width: 200px;
}

.order-details--row-two,
.customer-details--row-two {
  margin-top: 25px;
}

.order-details--key,
.customer-details--key {
  font-family: "moderat-medium";
  font-size: 13px;
  line-height: 12px;
  color: #a9a9bc;
}

.order-details--value,
.customer-details--value {
  font-family: "moderat-medium";
  line-height: 16px;
  color: #2e2e2e;
  margin-top: 8px;
}

/* Customer details Container */
.customer-details-container {
  width: 528px;
  height: max-content;
  padding: 20px 20px 20px 40px;

  background: #f8f8fa;
  border-radius: 5px;
  margin-top: 25px;
}

.customer-details--row-one {
  display: flex;
}

.customer-details--row-one div:first-child {
  width: 200px;
}
