.stock-setup-container {
  background-color: #ffffff;
}

/* All formgroups */
.product-variants-setup--inner .form-group,
.product-info-setup--inner .form-group,
.product-info-setup-size-category-form-group {
  margin-bottom: 25px;
}

/* All inputs */
.product-info-setup--inner .form-group select,
.product-info-setup--inner .form-group input,
.product-info-setup--inner form .form-group > textarea,
.product-variants-setup--inner .form-group input {
  width: 500px;
}

.product-info-setup-product-image-form-group {
  display: flex;
  align-items: center;
}

.product-info-setup-product-image-form-group .file-form-group--inner {
  width: 250px;
  position: relative;
}

.product-info-setup-product-image-form-group {
  margin-bottom: 15px;
}
.product-info-setup-product-image-form-group .file-form-group--inner {
  height: 100px;
  border: 1px dashed #c0bace;
  border-radius: 10px;
}

.product-info-setup-product-image-form-group .file-form-group--inner {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* File Input Wrapper */
.product-info-setup-product-image-form-group .file-input-wrapper {
  min-width: 160px;
  max-width: 400px;
  min-height: 33px;
  position: relative;
  background-color: #ede9f7;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* File Input */
.product-info-setup-product-image-form-group
  .file-form-group--inner
  .file-input-wrapper
  input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  outline: none;
}

/* File Input Title */
.product-info-setup-product-image-form-group
  .file-form-group--inner
  .file-input-wrapper
  .file-input-title {
  height: 100%;
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  color: #2e2e2e;
  padding: 0 3px;
}

.product-info-setup-product-image-form-group .file-form-group label {
  font-weight: 400;
  display: block;
  font-size: 14px;
  line-height: 14px;
  color: #2e2e2e;
  margin-bottom: 5px;
}

.product-info-setup-product-image-form-group .file-form-group label span {
  font-family: "moderat-light";
  font-style: italic;
  font-size: 12px;
  line-height: 12px;
  color: #2e2e2e;
}

.add-new-product-img-button-wrapper {
  margin-bottom: 25px;
}

.add-new-product-img-button-wrapper button {
  width: 180px;
  height: 27px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  background-color: #f8f8fa;
  font-size: 13px;
  line-height: 14px;
  color: #3a0ca3;
  border: none;
  outline: none;
  cursor: pointer;
  margin-left: 10px;
}

.add-new-variant-button-wrapper button {
  border: none;
  outline: none;
  margin-top: 40px;
  cursor: pointer;
}

.add-new-product-img-button-wrapper img {
  margin-right: 8px;
}

/* edit-variant-img-input */
.product-info-setup-product-image-form-group .edit-variant-img-input {
  margin-left: 20px;
  width: 140px;
  height: 33px;
  position: relative;
  background-color: #ede9f7;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
}

.edit-variant-img-input input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  outline: none;
}

.product-img-preview {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  top: 0;
  left: 0;
}

/* category-and-quantity-form-group-wrapper */
.category-and-quantity-form-group-wrapper,
.variant-quantity-and-price-form-group-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.category-and-quantity-form-group-wrapper .form-group input,
.category-and-quantity-form-group-wrapper .form-group select,
.variants-quatity-form-group input,
.variant-price-form-group input {
  width: 220px !important;
}

/* Product Brand */
.product-brand-setup-product-name-form-group {
  position: relative;
}

.product-brand-setup-product-name-form-group.form-group-active
  .form-group
  input {
  outline: 1px solid #3a0ca3;
}

/* Product Size category radio */
.product-info-setup-size-category-form-group {
  display: flex;
  align-items: center;
}

.product-info-setup-size-category-form-group .form-check:nth-child(2) {
  margin: 0 15px;
}

.product-info-setup-size-category-form-group .form-check input {
  transform: translateY(2.5px);
  margin-left: 0;
  accent-color: #3a0ca3;
  width: 16px;
  height: 16px;
}

/* Variants checkbox form group  */
.product-info-setup-variants-exists-form-group {
  display: flex;
  align-items: center;
  margin-top: 40px;
}

.product-info-setup-variants-exists-form-group input {
  width: 16px;
  height: 16px;
  accent-color: #3a0ca3;
  transform: translateY(1px);
  outline: none;
}

.product-info-setup-variants-exists-form-group input:focus,
.product-info-setup-size-category-form-group .form-check input:focus {
  outline: none;
  border: none;
}

.product-info-setup-variants-exists-form-group label {
  color: #1a0404;
  padding-left: 4px;
}

/*Prouct Variant setup container */
.product-variant-container {
  margin-bottom: 40px;
}

.product-variant-container .form-header-wrapper,
.add-new-variant-button-wrapper button {
  width: 460px;
  height: 27px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  background-color: #f8f8fa;
  font-size: 14px;
  line-height: 14px;
  color: #3a0ca3;
  margin-bottom: 20px;
}

.add-new-variant-button-wrapper button {
  border: none;
  outline: none;
  margin-top: 40px;
  cursor: pointer;
}

.add-new-variant-button-wrapper img {
  margin-right: 8px;
}

/* Variant Preview view wrapper */
.variant-preview-view-wrapper {
  display: flex;
}

/* Left Wrapper */
.variant-preview-img-wrapper {
  width: 250px;
  height: 199px;
  overflow: hidden;
  border: 1px dashed #c0bace;
  border-radius: 10px;
}

.variant-preview-img-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Right Wrapper  */
.variant-preview-info-wrapper {
  padding-left: 30px;
}

.edit-variant-button {
  margin-top: 8px;
}

.edit-variant-button button {
  width: 99px;
  height: 33px;
  background: #ede9f7;
  border-radius: 5px;
  font-size: 13px;
  line-height: 13px;
  color: #2e2e2e;
  border: none;
  outline: none;
  cursor: pointer;
}

/* variant-preview-info--inner */
.variant-preview-info--inner {
  margin-top: 50px;
}

.preview--product-name-wrapper {
  font-family: "moderat-semibold";
  font-size: 16px;
  line-height: 18px;
}

.preview--variant-specs-wrapper,
.preview--variant-quantity-wrapper,
.preview--variant-price-wrapper {
  font-size: 14px;
  line-height: 14px;
  margin: 8px 0;
}
.preview--variant-specs-wrapper {
  color: #b5179e;
}

.preview--variant-quantity-wrapper {
  color: #a9a9bc;
}

.preview--variant-price-wrapper {
  color: #3a0ca3;
  margin-top: 10px;
  font-size: 15px;
}
