.brands-popup-container {
  width: 400px;
  height: 250px;
  top: 30px;
  position: absolute;
  left: 0;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 1px 6px 0 rgb(0 0 0 / 20%);
  z-index: 40;
}

.brands-popup-search-wrapper,
.brands-popup-search-wrapper input {
  position: relative;
  width: 100%;
}

.brands-popup-search-wrapper input {
  height: 50px;
  background: #ffffff;
  box-shadow: 0 2px 3px 0 rgb(0 0 0 / 10%);
  padding: 5px 10px 5px 45px;
  outline: 0;
  border: 0;
  font-size: 15px;
  color: #1a0404;
  font-weight: 500;
}

.brands-popup-search-icon-wrapper {
  position: absolute;
  top: 13px;
  left: 15px;
  z-index: 20;
}

.brands-popup-search-icon-wrapper img {
  height: 25px;
}

.available-brands-display-wrapper {
  padding: 20px 0;
  height: 200px;
  overflow-y: auto;
}

.available-brands-wrapper-title {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 600;
  margin-bottom: 5px;
  padding: 0 20px;
}

.brands-popup-wrapper {
  padding: 7px 20px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.8);
  cursor: pointer;
}

.brands-popup-wrapper:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.brands-loader-spinner-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
