.merchant-agreement-container-main-section {
  overflow-x: hidden;
  padding: 40px 32px;
  background-color: rgb(235, 231, 246);
  margin-top: 80px;
}

@media (max-width: 850px) {
  .merchant-agreement-container-main-section {
    padding: 40px 16px;
  }
}

@media (max-width: 409px) {
  .merchant-agreement-container-main-section {
    padding: 40px 12px;
  }
}
