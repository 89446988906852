.form-group input[type="tel"] {
  padding-left: 55px;
}

.country-code-label {
  position: absolute;
  top: 28px;
  z-index: 99;
  line-height: 25px;
  padding: 2px;
  margin-left: 12px;
}
