.form-group input {
  height: 50px;
  padding: 0 15px;
  border: 1px solid #c0bace;
  box-sizing: border-box;
  border-radius: 10px;
  /* text styles  */
  font-size: 15px;
  line-height: 25px;
  /* font-family: "moderat-regular"; */
  font-weight: 400;
  color: #1a0404;
  background-color: transparent;
}

.form-group label {
  font-weight: 400;
  display: block;
  font-size: 14px;
  line-height: 14px;
  color: #2e2e2e;
  margin-bottom: 5px;
}

.form-group label span {
  display: inline-block;
  padding-left: 10px;
  font-family: "moderat-light";
  font-weight: 300;
  font-size: 12px;
  line-height: 12px;
  color: #b4b4b4;
}

.form-group input::placeholder {
  color: #a9a9bc;
  font-weight: 400;
}
