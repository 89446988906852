.dashboard-message-wrapper .error-handler-container {
  top: 135px !important;
}

.merchant-dashboard-container-wrapper {
  padding-bottom: 60px;
}

/* Merchant dashboard ROW ONE SECTION */
section.merchant-container-row--one {
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px 30px;
}

.row-one--left-container,
.row-one--middle-container,
.row-one--right-container {
  width: 100%;
  height: 150px;
  border: 1px solid #ede9f7;
  border-radius: 20px;
  padding: 15px 25px 0px;
}

.row-one--middle-container {
  margin: 0 10px;
}

/* Row one Left, Middddle and Right Container */
.row-one--left--top-wrapper,
.row-one--left--bottom-wrapper,
.row-one--middle--top-wrapper,
.row-one--middle--bottom-wrapper,
.row-one--right--top-wrapper,
.row-one--right--bottom-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.row-one--box-title {
  font-size: 14px;
  font-family: "moderat-semibold";
  line-height: 12px;
  text-transform: uppercase;
  color: #0d0520;
}

.make-withdrawal-btn-container button {
  width: 120px;
  height: 33.33px;
  background: #3a0ca3;
  border-radius: 10px;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 12px;
  line-height: 12px;
  color: #ffffff;
  transition: all ease-in-out 0.2s;
}

.make-withdrawal-btn-container button:hover {
  background: #320a90;
}

.sales-level-sort-dropdown-wrapper,
.stock-level-sort-dropdown-wrapper {
  position: relative;
}

.stock-level-sort-dropdown-wrapper .select-dropdown-wrapper,
.sales-level-sort-dropdown-wrapper .select-dropdown-wrapper {
  background-color: #f8f8fa;
}

.sales-level-sort-dropdown-wrapper select,
.stock-level-sort-dropdown-wrapper select {
  width: 110px;
  height: 33.33px;
  display: flex;
  align-items: center;
  padding-left: 8px;
  border-radius: 10px;
  border: none;
  outline: none;
  background-color: transparent;
  box-sizing: border-box;
  font-style: normal;
  /* font-weight: 500; */
  font-size: 13px;
  line-height: 12px;
  color: #dadada;
  position: relative;
  z-index: 4;
}

.sales-level-sort-dropdown-wrapper select option,
.stock-level-sort-dropdown-wrapper select option {
  font-size: 14px;
  color: #0d0520;
}

.sales-level-sort-dropdown-wrapper .dropdown-icon img,
.stock-level-sort-dropdown-wrapper .dropdown-icon img {
  width: 19.85px;
  height: 17.17px;
  position: absolute;
  height: 24px;
  top: 6px;
  right: 5px;
  filter: invert(1);
  z-index: 1;
}

/* row-one--left, middle and right --bottom-wrapper */
.row-one--left--bottom-wrapper,
.row-one--middle--bottom-wrapper,
.row-one--right--bottom-wrapper {
  margin-top: 30px;
}

/* wallet-balance-value-wrapper && sales-level-percentage-wrapper */
.wallet-balance-value-wrapper,
.sales-level-percentage-wrapper,
.stock-level-percentage-wrapper {
  font-family: "moderat-medium";
  font-size: 36px;
  line-height: 38px;
  color: #3a0ca3;
}

.row--one-percent-change-wrapper {
  width: 75px;
  height: 39px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;

  /* identical to box height, or 117% */
}

.percent-increase-wrapper {
  background-color: rgba(0, 186, 7, 0.1);
  color: #00ba07;
}

.percent-decrease-wrapper {
  background-color: rgba(226, 49, 49, 0.1);
  color: #e23131;
}

.row--one-percent-change-wrapper img {
  transform: translateY(1px);
  padding-left: 5px;
}

/* Merchant dashboard ROW TWO SECTION && PART OF Merchant dashboard ROW THREE SECTION  */
section.merchant-container-row--two,
section.merchant-container-row--three {
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(510px, 1fr));
  gap: 20px 30px;
}

/*  row-TWO && THREE-main-container */
.row-two-main-container,
.row-three-main-container {
  width: 100%;
  height: 350px;
  border: 1px solid #ede9f7;
  border-radius: 20px;
  padding: 15px 25px 0px;
}

.row-two--left--top-wrapper,
.row-two--right--top-wrapper,
.row-three--left--top-wrapper,
.row-three--right--top-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.row-two--box-title,
.row-three--box-title {
  font-size: 14px;
  font-family: "moderat-semibold";
  line-height: 12px;
  text-transform: uppercase;
  color: #0d0520;
}

.merchant-revenue-sort-dropdown-wrapper,
.merchant-order-sort-dropdown-wrapper,
.merchant-top-sellers-sort-dropdown-wrapper,
.merchant-out-of-stock-sort-dropdown-wrapper {
  position: relative;
}

.merchant-revenue-sort-dropdown-wrapper .select-dropdown-wrapper,
.merchant-order-sort-dropdown-wrapper .select-dropdown-wrapper,
.merchant-top-sellers-sort-dropdown-wrapper .select-dropdown-wrapper,
.merchant-out-of-stock-sort-dropdown-wrapper .select-dropdown-wrapper {
  background-color: #f8f8fa;
}

.merchant-revenue-sort-dropdown-wrapper select,
.merchant-order-sort-dropdown-wrapper select,
.merchant-top-sellers-sort-dropdown-wrapper select,
.merchant-out-of-stock-sort-dropdown-wrapper select {
  position: relative;
  width: 110px;
  height: 33.33px;
  display: flex;
  align-items: center;
  padding-left: 8px;
  background-color: transparent;
  border-radius: 10px;
  border: none;
  outline: none;
  box-sizing: border-box;
  font-style: normal;
  /* font-weight: 500; */
  font-size: 13px;
  line-height: 12px;
  color: #dadada;
  z-index: 4;
}
.merchant-revenue-sort-dropdown-wrapper select option,
.merchant-order-sort-dropdown-wrapper select option,
.merchant-top-sellers-sort-dropdown-wrapper select option,
.merchant-out-of-stock-sort-dropdown-wrapper select option {
  font-size: 14px;
  color: #0d0520;
}

.merchant-revenue-sort-dropdown-wrapper .dropdown-icon img,
.merchant-order-sort-dropdown-wrapper .dropdown-icon img,
.merchant-top-sellers-sort-dropdown-wrapper .dropdown-icon img,
.merchant-out-of-stock-sort-dropdown-wrapper .dropdown-icon img {
  width: 19.85px;
  height: 17.17px;
  position: absolute;
  height: 24px;
  top: 6px;
  right: 5px;
  filter: invert(1);
  z-index: 1;
}

.row-two--left--bottom-wrapper,
.row-two--right--bottom-wrapper,
.row-three--left--bottom-wrapper,
.row-three--right--bottom-wrapper {
  margin-top: 25px;
}

.total-revenue-amount-title,
.total-order-amount-title,
.top-selling-products-table-title,
.out-of-stock-products-table-title {
  font-family: "moderat-medium";
  font-size: 15px;
  line-height: 12px;
  color: #a9a9bc;
}

.total-revenue-amount-value,
.total-order-amount-value {
  font-family: "moderat-medium";
  font-size: 24px;
  line-height: 38px;
}

.total-revenue-amount-value {
  color: #3a0ca3;
}
.total-order-amount-value {
  color: #b5179e;
}

/* Revenue Chart Container */
.revenue-area-chart-container,
.order-area-chart-container {
  /* max-width: 30vw;
  width: 100%;
  height: 210px; */
  height: 210px;
  /* Text styles */
  padding-top: 10px;
  font-weight: 500;
  font-size: 13px;
  line-height: 10px;
  color: #a9a9bc;
}

/* Merchant dashboard ROW THREE SECTION */

/* top-selling-products-table-container */
.top-selling-products-table-container,
.out-of-stock-products-table-container {
}

.top-selling-products-table,
.out-of-stock-products-table {
  width: 100%;
  /* height: 210px; */
  /* padding: 10px 20px; */
  background-color: #ffffff;
  margin-top: 20px;
  border-radius: 10px;
  overflow: hidden;
  /* Remove table gaps and margins */
  border-spacing: 0px;
}

.top-selling-products-table thead,
.out-of-stock-products-table thead {
  background-color: #f0ecf8;
}
/* Table Head , Table Row */
.top-selling-products-table thead tr,
.out-of-stock-products-table thead tr {
  border-radius: 10px;
}

/* Table Head , Table Row, Thable header*/
.top-selling-products-table thead tr th,
.out-of-stock-products-table thead tr th {
  padding: 14px 10px;
  font-family: "moderat-semibold";
  font-size: 12px;
  line-height: 12px;
  color: #3a0ca3;
}

.top-selling-products-table thead tr th:nth-child(1),
.out-of-stock-products-table thead tr th:nth-child(1) {
  padding-left: 65px;
}

/* Table Body */
.top-selling-products-table tbody,
.out-of-stock-products-table tbody {
  transform: translateY(-5px);
  margin: 0;
}

.top-selling-products-table tbody tr {
  overflow: hidden;
}

/* Table Body , Table Data*/
.top-selling-products-table tbody tr td,
.out-of-stock-products-table tbody tr td {
  background-color: #f8f8fa;
  padding: 12px 10px;
  font-family: "moderat-medium";
  color: #2e2e2e;
  font-size: 15px;
}

.top-selling-products-table tbody tr td:nth-child(1),
.out-of-stock-products-table tbody tr td:nth-child(1) {
  padding-left: 10px;
  border-radius: 10px 0 0 10px;
}

.top-selling-products-table tbody tr td:nth-last-child(1),
.out-of-stock-products-table tbody tr td:nth-last-child(1) {
  border-radius: 0 10px 10px 0;
}

/* Prouct Name Wrapper */
.td-product-name--wrapper {
  display: flex;
  align-items: center;
}

.td-product-name--wrapper .profile-image-wrapper {
  overflow: hidden;
  width: 40px;
  height: 40px;
  /* object-fit: cover; */
  border-radius: 4px;
  margin-right: 15px;
}

.td-product-name--wrapper .profile-image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.td-product-status--wrapper {
  color: #e23131;
}

/* Media Queries */
@media (min-width: 1400px) {
  .merchant-dashboard-container--inner {
    max-width: 1460px;
    margin: auto;
    padding: 0 30px;
  }

  .row-one--left-container,
  .row-one--middle-container,
  .row-one--right-container {
    height: 150px;
  }

  .row-two-main-container,
  .row-three-main-container {
    height: 380px;
    border: 1px solid #ede9f7;
    border-radius: 20px;
  }
}
