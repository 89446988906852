.app-navbar-container {
  top: 0;
  width: 100%;
  z-index: 40;
  background-color: #f8f8fa;
  padding: 0 16px;
  font-family: moderat-regular;
}

.app-navbar-container--inner {
  max-width: 1200px;
  height: 80px;
  margin: auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Navbar Links  Wrapper Container */
.navbar-links-wrapper-container {
  display: flex;
}

/* Navbar Link Wrappr */
.navbar-link-wrapper {
}

.navbar-link-wrapper:nth-child(2) {
  margin: 0 70px;
}

.navbar-link-wrapper a {
  text-decoration: none;
  font-family: "moderat-medium";
  font-size: 17px;
  line-height: 20px;
  color: #a9a9bc;
}

/* Active Navbar Link */
.active-navbar-link {
  background: linear-gradient(281.7deg, #b5179e 3.1%, #3a0ca3 96.25%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
