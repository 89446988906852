.merchant-signup-container {
}
.merchant-signup-container--inner {
  max-width: 2000px;
  margin: auto;
  padding-top: 80px;
  min-height: 100vh;
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  position: relative;
  overflow: hidden;
}

.auth-error-message {
  position: absolute;
  max-width: 561px;
  min-height: max-content;
  min-height: 40px;
  right: 80px;
  top: 100px;
  background-color: rgba(255, 72, 72, 0.2);
  border: 1px solid rgba(255, 72, 72, 1);
  padding: 8px 15px;
  color: #2e2e2e;
  font-size: 15px;
}

/* Merchant Signup Left section  / Auth Left Section*/
.auth-left-section {
  grid-column: span 5 / span 5;
  background-color: #fbf9ff;
  overflow: hidden;
  position: relative;
}
/* Merchant Signup Right section  / Auth Right Section*/
.auth-right-section {
  grid-column: span 7 / span 7;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  position: relative;
}

/* Merchant Signup Left section */
/* merchant-signup-left-section-text-wrapper */
.merchant-signup-left-section-text-wrapper {
  width: 364px;
  font-weight: 700;
  font-size: 40px;
  line-height: 54px;
  background: linear-gradient(281.7deg, #b5179e 3.1%, #3a0ca3 96.25%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin-top: 15%;
  transform: translateX(20%);

  /* font-family: moderat-semibold; */
}

.left-auth-section-image-wrapper {
  position: absolute;
  bottom: -5px;
  left: 0;
}

.left-auth-section-image-wrapper img {
  max-width: 517px;
}

/* Merchant Signup Right section */
.merchant-signup-right-section--inner {
  margin-top: 11%;
}

/* Header Title  */
.merchant-signup-right-section-header-title {
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  color: #1a0404;
}

/* Header Sub Title  */
.merchant-signup-right-section-header-sub-title {
  font-size: 16px;
  line-height: 24px;
  color: #2e2e2e;
}

.merchant-signup-right-section-header-sub-title span a {
  text-decoration: none;
  color: #b5179e;
  font-family: "moderat-semibold";
}

/* Merchant Signup main form section */
.merchant-signup-main-form-section {
  margin-top: 40px;
}

.merchant-signup-main-form-section form {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  column-gap: 30px;
  /* row-gap: 30px; */
}

.merchant-signup-main-form-section form .form-group {
  grid-column: span 6 / span 6;
  margin-bottom: 30px;
}

.merchant-shop-name-form-group input,
.merchant-shop-email-form-group input,
.merchant-phone-number-one-form-group input,
.merchant-phone-number-two-form-group input,
.merchant-signup-password-form-group input,
.merchant-signup-confirm-password-form-group input {
  width: 300px;
}

.password-not-matching-error-message-wrapper {
  grid-column: span 12 / span 12;
  transform: translateY(-20px);
  font-size: 15px;
  line-height: 25px;
  color: #ff473e;
}

/* Submit form button wrapper */
.merchant-signup-button-wrapper {
  grid-column: span 12 / span 12;
  display: flex;
  justify-content: flex-end;
  margin-top: 25px;
}

.merchant-signup-button-wrapper button {
  width: 250px;
  height: 50px;
  background: #3a0ca3;
  border-radius: 10px;
  border: none;
  outline: none;
  cursor: pointer;
  /* Text styles */
  font-weight: "moderat-medium";
  font-size: 14px;
  line-height: 15px;
  color: #ffffff;
  margin-left: 10px;
}

/* Not a merchant wrapper wrapper  */
.not-a-merchant-wrapper {
  grid-column: span 12 / span 12;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 15px;
  line-height: 24px;
  color: #2e2e2e;
  margin-top: 20px;
}

.not-a-merchant-wrapper--inner {
  width: 300px;
}

/* Row one */
.not-a-merchant-wrapper .row-one {
  text-align: right;
}

.not-a-merchant-wrapper .row-one span a {
  font-size: 16px;
  text-decoration: none;
  padding: 0 5px;
  color: #3a0ca3;
  font-family: "moderat-semibold";
}

/* TERMS ND CONDITONS */
.terms-conditions-form-group {
  grid-column: span 12 / span 12;
  display: flex;
  align-items: center;
  margin-top: 8px;
  /* margin-left: 8px; */
}

.terms-conditions-form-group input {
  width: 16px;
  height: 16px;
  accent-color: #3a0ca3;
  transform: translateY(1px);
}

.terms-conditions-form-group input:focus {
  outline: none;
  border: none;
}

.terms-conditions-form-group label {
  color: #222222;
  padding-left: 4px;
}

.terms-conditions-form-group label a {
  color: #3a0ca3;
  text-decoration: none;
}

.none {
  display: none;
}

/* Media Queries */

@media (max-width: 1200px) {
  .auth-left-section {
    grid-column: span 4 / span 4 !important;
  }

  .auth-right-section {
    grid-column: span 8 / span 8 !important;
  }
}

@media (max-width: 1080px) {
  .merchant-signup-left-section-text-wrapper {
    transform: translateX(5%);
  }
}
@media (max-width: 1003px) {
  .merchant-signup-main-form-section form .form-group {
    grid-column: span 10 / span 10;
  }

  .merchant-shop-name-form-group input,
  .merchant-shop-email-form-group input,
  .merchant-phone-number-one-form-group input,
  .merchant-phone-number-two-form-group input,
  .merchant-signup-password-form-group input,
  .merchant-signup-confirm-password-form-group input {
    width: 350px;
  }

  .merchant-signup-button-wrapper {
    grid-column: span 10 / span 10;
  }

  .not-a-merchant-wrapper {
    grid-column: span 10 / span 10;
  }
}

@media (max-width: 990px) {
  .merchant-signup-left-section-text-wrapper {
    width: 320px;
    font-size: 36px;
  }

  .merchant-signup-right-section-header-title {
    font-size: 36px;
  }

  .left-auth-section-image-wrapper img {
    max-width: 417px;
  }

  .signin-main-form-section {
    margin-top: 35px;
  }
}

@media (max-width: 830px) {
  .auth-left-section {
    display: none;
  }

  .auth-right-section {
    grid-column: span 12 / span 12 !important;
  }

  .log-in-button-wrapper {
    justify-content: center;
  }

  .merchant-signup-main-form-section form .form-group {
    grid-column: span 12 / span 12;
  }

  .merchant-shop-name-form-group input,
  .merchant-shop-email-form-group input,
  .merchant-phone-number-one-form-group input,
  .merchant-phone-number-two-form-group input,
  .merchant-signup-password-form-group input,
  .merchant-signup-confirm-password-form-group input {
    width: 350px;
  }

  .merchant-signup-button-wrapper {
    grid-column: span 12 / span 12;
    justify-content: center;
  }

  .not-a-merchant-wrapper {
    grid-column: span 12 / span 12;
    justify-content: center;
  }

  .not-a-merchant-wrapper .row-one {
    text-align: center;
  }

  .not-a-merchant-wrapper--inner {
    width: 100%;
  }
}

@media (max-width: 575px) {
  .auth-right-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .merchant-signup-right-section--inner {
    margin-top: 20px;
  }

  .auth-message-wrapper .error-handler-container {
    top: 5px;
    z-index: 40;
    position: relative;
  }
}

@media (max-width: 525px) {
  .merchant-signup-right-section--inner {
    width: 300px;
  }

  .merchant-shop-name-form-group input,
  .merchant-shop-email-form-group input,
  .merchant-phone-number-one-form-group input,
  .merchant-phone-number-two-form-group input,
  .merchant-signup-password-form-group input,
  .merchant-signup-confirm-password-form-group input {
    width: 300px;
  }

  .merchant-signup-main-form-section form {
    column-gap: 0;
  }
}
