.add-another-stock-link-button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 45px;
}

.add-another-stock-link-button button {
  width: 250px;
  height: 50px;
  background-color: #3a0ca3;
  border-radius: 10px;
  border: none;
  outline: none;
  cursor: pointer;
  /* Text styles */
  font-weight: 500;
  font-size: 17px;
  line-height: 15px;
  color: #ffffff;
  margin-left: 10px;
}
