.fund-withdrawal-container {
  padding-bottom: 30px;
  position: relative;
  overflow: hidden;
}

.fund-withdrawal-container--inner {
  max-width: 1100px;
  margin: auto;
  padding: 50px 0;
  padding-left: 50px;
  position: relative;
}

.fund-withdrawal-top-container {
  margin-left: 55px;
}

.fund-withdrawal-top--left {
  display: flex;
  align-items: center;
}

.fund-withdrawal-top-container .back-button-wrapper button {
  width: 50px;
  height: 50px;
  background-color: #f8f8fa;
  border-radius: 10px;
  border: none;
  outline: none;
  cursor: pointer;
}

.fund-withdrawal-top-title {
  font-family: "moderat-semibold";
  font-size: 32px;
  line-height: 50px;
  text-align: center;
  color: #1a0404;
  margin-left: 25px;
}

/* fund-withdrawal-row--one */
.fund-withdrawal-row-section,
.withdrawal-history-row-section {
  margin-left: 130px;
}

.fund-withdrawal-row--one {
  margin-top: 40px;
  display: flex;
}

.fund-withdrawal--wallet-balance-container {
  padding: 20px 25px 0px;
}

.fund-withdrawal--wallet-balance-container .row-one--left--bottom-wrapper {
  margin-top: 35px;
}

.fund-withdrawal-row--one--right {
  margin-left: 60px;
}

.amount-to-withdraw-form-group input {
  width: 247px;
}

.withdraw-amount-btn-wrapper {
  margin-top: 26px;
}

.withdraw-amount-btn-wrapper button {
  width: 250px;
  height: 50px;
  background-color: #3a0ca3;
  border-radius: 10px;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 12px;
  line-height: 12px;
  color: #ffffff;
  transition: all ease-in-out 0.2s;
}

.withdraw-amount-btn-wrapper button:hover {
  background: #320a90;
}

/* withdrawal history row section */
.fund-withdrawal-row--two {
  margin-top: 60px;
  max-width: 850px;
}

.withdrawal-history-row-top-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.withdrawal-history-section-title {
  font-family: "moderat-semibold";
  font-size: 15px;
  line-height: 12px;
  color: #0d0520;
}

/* Withdrawal History Table Container */
.withdrawal-history-table {
  width: 100%;
  /* height: 210px; */
  /* padding: 10px 20px; */
  background-color: #ffffff;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  overflow: hidden;
  /* Remove table gaps and margins */
  border-spacing: 0px;
}

.withdrawal-history-table thead {
  background-color: #f0ecf8;
}
/* Table Head , Table Row */
.withdrawal-history-table thead tr {
  border-radius: 10px;
}

/* Table Head , Table Row, Thable header*/
.withdrawal-history-table thead tr th {
  padding: 14px 10px;
  font-family: "moderat-semibold";
  font-size: 12px;
  line-height: 12px;
  color: #3a0ca3;
  padding-left: 65px;
}

.withdrawal-history-table thead tr th:nth-child(1) {
}

/* Table Body */
.withdrawal-history-table tbody {
  transform: translateY(-5px);
  margin: 0;
}

.withdrawal-history-table tbody tr {
  overflow: hidden;
}

/* Table Body , Table Data*/
.withdrawal-history-table tbody tr td {
  background-color: #f8f8fa;
  padding: 20px 10px;
  font-family: "moderat-medium";
  color: #2e2e2e;
  font-size: 15px;
  padding-left: 65px;
}

.withdrawal-history-table tbody tr td:nth-child(1) {
  border-radius: 10px 0 0 10px;
}

.withdrawal-history-table tbody tr td:nth-last-child(1) {
  border-radius: 0 10px 10px 0;
}

.successful-withdrawal-wrapper {
  color: #00ba07;
}

.failed-withdrawal-wrapper {
  color: #ff4848;
}

.overflow-hidden {
  overflow: hidden;
  height: 100vh;
}

.none {
  display: none;
}

.gray-overlay {
  position: absolute;
  top: 0;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  z-index: 40;
  transition: all ease-in-out 0.5s;
}
