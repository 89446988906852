/* Primary table section */
section.primary-table-section {
  margin-top: 60px;
}

/* primary-table--top-wrapper */
.primary-table--top-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* primary-table-filter-option-wrapper */
.primary-table-filter-option-wrapper {
  display: flex;
  align-items: center;
}

.primary-table-filter-row-title {
  font-family: "moderat-medium";
  /* font-size: 15px; */
  line-height: 15px;
  color: #a9a9bc;
  margin-right: 10px;
}

.primary-table-filter-option-wrapper .form-group {
  margin-left: 20px;
}

.primary-table-filter-option-wrapper .select-dropdown-wrapper {
  background: #ffffff;
}

.primary-table-filter-option-wrapper .form-group select {
  width: 150px;
  border: none;
  color: #dadada;
  background-color: transparent;
  position: relative;
  z-index: 4;
}

.primary-table-filter-option-wrapper select option {
  font-size: 14px;
  color: #0d0520;
  z-index: 1;
}

.primary-table-filter-option-wrapper .form-group .dropdown-icon img {
  top: 18px;
}

.primary-table-container {
  margin-top: 40px;
  /* max-width: 1180px; */
  overflow-x: auto;
  overflow-y: hidden;
}

.primary-table {
  width: 100%;
  background-color: #f0ecf8;
  margin-top: 20px;
  border-radius: 10px;
  overflow: hidden;
  /* Remove table gaps and margins */
  border-spacing: 0px;
}

.primary-table thead {
  background-color: #f0ecf8;
}
/* Table Head , Table Row */
.primary-table thead tr {
  border-radius: 5px 5px 0px 0px;
}

/* Table Head , Table Row, Thable header*/
.primary-table thead tr th {
  padding: 20px 10px;
  font-family: "moderat-semibold";
  font-size: 14px;
  line-height: 12px;
  color: #3a0ca3;
}

.primary-table thead tr th:nth-child(1) {
  padding-left: 50px;
}

/* Table Body */
.primary-table tbody {
  margin: 0;
}

.primary-table tbody tr {
  overflow: hidden;
}

.primary-table tbody tr:hover td {
  background-color: #f8f8fa;
}

/* Table Body , Table Data*/
.primary-table tbody tr td {
  background-color: #ffffff;
  padding: 16px 10px;
  font-family: "moderat-medium";
  color: #2e2e2e;
  font-size: 15px;
  border-bottom: 1px solid #f3f3f3 !important;
}

.primary-table tbody tr td:nth-child(1) {
  padding-left: 50px;
}

/*  Name Wrapper */
.primary-table .td-employee-name--wrapper {
  display: flex;
  align-items: center;
  max-width: 300px;
}

.primary-table .td-employee-name--wrapper .profile-image-wrapper {
  overflow: hidden;
  width: 40px;
  height: 40px;
  /* object-fit: cover; */
  border-radius: 4px;
  margin-right: 15px;
}

.primary-table .td-employee-name--wrapper .profile-image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.td-email--wrapper {
  word-wrap: break-word;
  max-width: 180px;
}

.table-top-loader-wrapper {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 1050px) {
  .primary-table thead tr th:nth-child(1) {
    padding-left: 25px;
  }

  .primary-table tbody tr td:nth-child(1) {
    padding-left: 25px;
  }
}
