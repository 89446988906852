.confirm-withdrawal-modal-container {
  position: absolute;
  width: 318px;
  height: 319px;
  z-index: 50;
  /* transform: translate(50%); */
  left: 40%;
  top: 25%;
  background: #ffffff;
  border-radius: 20px;
}

.confirm-withdrawal-modal-container--inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 35px;
}

.confirm-withdrawal-header-text {
  font-family: "moderat-semibold";
  font-size: 20px;
  line-height: 20px;
  color: #1a0404;
  margin-top: 20px;
}

.confirm-withdrawal-sub-text {
  width: 213px;
  font-size: 12.5px;
  line-height: 15px;
  text-align: center;
  color: #a9a9bc;
  margin-top: 20px;
}

.confirm-withdrawal-sub-text span {
  color: #3a0ca3;
}

.modal-action-button-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
}

.cancel-modal-button-wrapper button,
.confirm-modal-action-button button {
  border: none;
  outline: none;
  cursor: pointer;
}

.cancel-modal-button-wrapper button {
  background-color: inherit;
  font-family: "moderat-medium";
  font-size: 15px;
  line-height: 15px;
  color: #ff4848;
}

.confirm-modal-action-button button {
  width: 169px;
  height: 50px;
  background: #3a0ca3;
  border-radius: 10px;
  font-family: "moderat-medium";
  font-size: 15px;
  line-height: 15px;
  color: #ffffff;
  transition: all ease-in-out 0.2s;
}

.confirm-modal-action-button button:hover {
  background: #320a90;
}
